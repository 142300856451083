import React from 'react'
const scriptSrc = 'https://www.opentable.com/widget/reservation/loader?rid=106771&type=standard&theme=standard&color=1&dark=false&iframe=true&domain=com&lang=en-US&newtab=false&ot_source=Restaurant%20website&cfe=true';

export const OpenTable = () => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current.innerHTML = '';
    const script = document.createElement('script');
    script.src = scriptSrc;
    script.async = true;
    ref.current.appendChild(script);
    return () => {
      if (ref.current?.contains(script)) {
        ref.current.removeChild(script);
      }
    }
  }, [ref]);
  return <div ref={ref} id="reservation"></div>
}